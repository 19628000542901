<template>
  <v-card class="py-5">
    <v-card-title  class="justify-center text-h4 pb-10 primary--text"
      >{{isUpdate? "แก้ไข" : "สร้าง" }}ข้อมูลแจ้งเตือน</v-card-title
    >
    
    <v-card-text class="popup-background">
      <v-row   justify="center">
        <v-col cols="5">
          <!-- <v-text-field name="create_date" v-model="form.create_date"  label="วันที่สร้าง" outlined dense background-color="white"  /> -->
          <DatePicker
            disabled
            name="create_date"
            label="วันที่สร้าง"
            v-model="form.create_date"
            @onChange="(val) => form.create_date = val"
          />
          <v-text-field name="topic" v-model="form.topic" label="ชื่อเรื่อง" outlined dense background-color="white" :error-messages="$v.form.topic.$error ? $error_text : ''"/>
          <v-textarea   name="detail" v-model="form.detail" label="รายละเอียด" outlined dense background-color="white" />
          <v-select
            v-model="form.responsibility_user"
            :error-messages="$v.form.responsibility_user.$error ? $error_text : ''"
            :items="list.responsibility_user"
            item-value="_id"
            item-text="name"
            chips
            label="ผู้รับผิดชอบ"
            multiple
            dense
            outlined
          ></v-select>
          <DatePicker
            name="alert_date"
            label="วันที่เตือน"
            v-model="form.alert_date"
            :value="form.alert_date"
            @onChange="(val) => form.alert_date = val"
          />
          <!-- <v-text-field name="alert_time" v-model="form.alert_time" label="เวลาที่เตือน" outlined dense background-color="white" :error-messages="$v.form.f_a.$error ? $error_text : ''"/> -->
          <TimePicker
              name="alert_time"
              label="เวลาที่เตือน"
              v-model="form.alert_time"
              :value="form.alert_time"
              @onChange="(val) => form.alert_time = val"
            />
        </v-col>
        <v-col cols="5">
          <div class="mb-8">
            <menu-ship
              v-model="form.is_available"
              :disabled="!isUpdate"
              path="internal/changed_internal_alert_status"
              :params="{ internal_alert_id: form._id }"
              @success="
                (val) => {
                  $alertSuccess(), (form.is_available = val);
                }
              "
            />
          </div>
          <v-text-field name="created_user" v-model="form.created_user" label="ชื่อผู้สร้าง" outlined dense background-color="white" disabled/>
          <!-- <v-select name="alert_repeat_trigger" v-model="form.alert_repeat_trigger" :items="['ไม่','ทุกวัน','ทุกสัปดาห์','ทุกปี']" label="เตือนซ้ำ" outlined dense background-color="white" /> -->
          <v-select name="alert_repeat_trigger" v-model="form.alert_repeat_trigger" label="เตือนซ้ำ" outlined :items="list.alert_repeat_trigger" item-value="value" item-text="name" dense background-color="white"></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    

    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">ปิด</v-btn>
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"  
      >
        บันทึกข้อมูล
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import TimePicker from "@/components/TimePicker.vue";
import { required, decimal } from "vuelidate/lib/validators";
import * as moment from "moment";

export default {
  components: {
    DatePicker,
    TimePicker
  },
  validations: {
    form: {
      topic: { required },
      responsibility_user: { required },
    },
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  watch: {
    id: function (newVal, oldVal) {
      console.log(this.id);
      this.getData(newVal);
    },
  }, 
  created () {
    if (this.isUpdate) this.getData(this.id);
    this.getUser();
    this.form.branch_id = this.$store.state.selected_branch._id;
    this.form.created_user = this.$store.state.profile.name;
    this.form.create_date = moment().format("YYYY-MM-DDTHH:mm:ss");
  },
  data() {
    return {
      loading: false,
      form: {
        topic: "",
        detail: "",
        responsibility_user: [],
        alert_time: "",
        alert_date: "",
        branch_id: "",
        is_available: false,
        alert_repeat_trigger: 0,
        internal_alert_id: "",
        create_date: moment().format("YYYY-MM-DD"),
        created_user: "",
      },
      list: {
        responsibility_user: [],
        alert_repeat_trigger: [
          { value: "0", name: "ครั้งเดียว" },
          { value: "1", name: "ทุกวัน" },
          { value: "2", name: "ทุกสัปดาห์" },
          { value: "3", name: "ทุกปี" },
        ],
      },
    };
  },
  
  getDateText(date){
    let yearOffset = 543;
    let d = moment(date);
    return d.format('DD/MM') + '/' + (parseInt(d.format('YYYY')) + yearOffset);
  },
  
  methods: {
    close() {
      this.$emit("onClose");
    },

    onChangeTime(val, kayName) {
      this.form[kayName] = `${val}:00`;
    },

    getUser() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true }),
      };
      this.$axios.post(`${this.$baseUrl}/users/get_user`, body)
        .then(async (res) => {
          this.list.responsibility_user = res.result.data;
        });
    },
    async getData(id) {
    if(id){
        let body = {
          token: this.$jwt.sign({ internal_alert_id: id }, this.$privateKey,{ noTimestamp: true }),
        };
        await this.$axios.post(`${this.$baseUrl}/internal/get_by_id`, body)
          .then(async (res) => {
            this.form = { ...res.result};
            this.form.alert_date = this.form.alert_time
            this.form.alert_time = moment().set({hour:parseInt(moment(this.form.alert_date).format('HH')),
                minute:parseInt(moment(this.form.alert_date).format('mm')),}).format("HH:mm")
          })
          .catch((err) => {
            console.log("err", err);
          });
      }else{
        this.form ={
          alert_id: "",
          create_date: moment().add(-1, 'days').format("YYYY-MM-DD"),
          topic: "",
          detail: "",
          responsibility_user: [],
          alert_time: "",
          alert_date: "",
          is_available: false,
          created_user: "",
          alert_repeat_trigger: 0,
          internal_alert_id: "",
        }
      } 

      this.loading = false;
    },
    resetForm(){
      this.form = {
        alert_id: "",
        create_date: moment().add(-1, 'days').format("YYYY-MM-DD"),
        topic: "",
        detail: "",
        responsibility_user: [],
        alert_time: "",
        alert_date: "",
        is_available: false,
        created_user: "",
        alert_repeat_trigger: 0,
        internal_alert_id: "",
      } 
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.loading = true;
      
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let subPath = "add_alert";
            this.form.alert_time = moment().set({
                year:parseInt(moment(this.form.alert_date).format('YYYY')),
                month:parseInt(moment(this.form.alert_date).format('MM')),
                day:parseInt(moment(this.form.alert_date).format('DD')),
                hour:parseInt(this.form.alert_time.split(':')[0]),
                minute:parseInt(this.form.alert_time.split(':')[1]),
                second:0
              }).format("YYYY-MM-DDTHH:mm")
            delete this.form.alert_date;
            delete this.form.create_date;
            delete this.form.created_user;
            this.form.alert_repeat_trigger = parseInt(this.form.alert_repeat_trigger);
            if (this.isUpdate) {
              subPath = "edit_alert";
              this.form = {internal_alert_id: this.form._id,  ...this.form};
              delete this.form._id;
            }
            console.log(this.form)
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, { noTimestamp: true }),
            };
            console.log(body)

            await this.$axios.post(`${this.$baseUrl}/internal/${subPath}`, body)
              .then((res) => {
                console.log(res)
                this.$alertSuccess({ title: res.status });
              })
              .catch((err) => {
                console.log("err", err);
                this.$alertServerError({ title: err.error_message });
              });
            console.log("save()");
            this.$emit("onSave");
          }
          this.loading = false;
        }
      ); 
    },
  },
  computed: {
    isUpdate() {
      return this.id;
    },
  },
};
</script>

